<template>
  <v-row>
    <v-col cols="12">
      <v-checkbox
        :input-value="TPFilter == 1"
        @change="handleTPFilter"
        class="mt-0 py-4 promo-checkbox-wrap"
        hide-details
        color="secondary"
      >
        <template v-slot:label>
          <v-chip class="ma-2 text-uppercase" label color="secondary">
            LISTINO
          </v-chip>
        </template></v-checkbox
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ProductCustomFilter",
  props: ["TPFilter"],
  methods: {
    handleTPFilter(onlyTP) {
      if (onlyTP) {
        onlyTP = "1";
      } else {
        onlyTP = null;
      }
      this.$emit("handleTPFilter", onlyTP);
    }
  }
};
</script>

<style scoped lang="scss">
.promo-checkbox-wrap {
  &::v-deep .v-input__slot {
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    padding: 1px 6px 1px 12px;
    max-width: fit-content;
  }
}
</style>
